import React, {useContext, useRef, useEffect, useState} from 'react';

import GuestViewContext from '../../../Context/GuestViewContext';

import moment from 'moment';
import 'moment-timezone';

import EventBoardDay from './EventBoardDay.js';

import makeStyles from '@mui/styles/makeStyles';
import { Card, Container, Fab, Grid, Paper, Typography, useMediaQuery, Zoom } from '@mui/material';

import {DateRange} from '@mui/icons-material';

import Calendar from '../../../lib/React-Calendar/index.js';
import './Calendar.css';

import useScrollTrigger from '@mui/material/useScrollTrigger';

import {serializeShow, checkMatch} from '../../../Common/searchUtils';
import { useParams } from 'react-router';
import EventBoardShow from './EventBoardShow';
import EventBoardFeaturedShow from './EventBoardFeaturedShow';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: '100%'
    },
    title: {
        marginBottom: '1%',
        height: '10%',
        display: "flex",
        position: 'relative',
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.primary.light
    },
    arrow: {
        display: 'flex',
        height: '100%'
    },
    main: {
        height: '100%',
        marginBottom: '5%'
    },
    arrowLeftContainer: {
        display: 'flex',
        justifyContent: "flex-start",
        alignItems: "flex-start",
        padding: '0 1%',

    },
    arrowRightContainer: {
        display: 'flex',
        justifyContent: "flex-end",
        alignItems: "flex-start",
        padding: '0 0',
        height: '100%',
        marginRight: '0'
    },
    card: {
        height: '100%',
        position: 'absolute',
        right: '0'
    },
    calendar: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: '100%',
        marginTop: '1%',
        marginBottom: '1%'
    },
    fab: {
        position: 'fixed',
        bottom: '2%',
        right: '2%',
        padding: "2%",
        backgroundColor: theme.palette.primary.light
    },
    show: {
        maxWidth: props => `calc(100% / ${props.maxDaysToShow})`
    },
    showDays: {
        padding: '0% 1%'
    },
    calendarLabel: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center"
    },
    showsOpen: {
        color: 'black',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.8em'
        },
    },
    callout: {
        paddingTop: '0.25%',
        display: 'flex',
        justifyContent: "center",
        backgroundColor: 'white',
        color: 'white'
    },
    featuredShowContainer: {
        width: '100%',
        height: '100%',
    },
    featuredShow: {
        margin: '0.5em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center",
        width: '100%',
        height: '100%'
    }
}))

function getAdjustedCalendarValueAndFormatDate(date, tzValue){
    let momentDate = moment.utc(date);
    let adjustedTZDate = momentDate.tz(tzValue).toDate();
    let formatDate = momentDate.tz(tzValue).format("ddd MM-DD");
    return { adjustedTZDate, formatDate }
}

const serializeCache = {};

function EventBoard(){
    let maxDaysToShow = 5;
    const guestViewState = useContext(GuestViewContext);
    const tzValue = guestViewState.timeZoneValue?.value || "America/Los_Angeles";
    const urlParams = new URLSearchParams(window.location.search);
    const paramHostName = urlParams.get('hostName');
    const paramExperience = urlParams.get('experience');

    const [selectedDateShowFirst, setSelectedDateShowFirst] = useState(true);
    const [featuredShow, setFeaturedShow] = useState(null);

    const { privateShowID } = useParams();

    const greaterThanXs = useMediaQuery(theme => theme.breakpoints.up("xs"));
    const greaterThanSm = useMediaQuery(theme => theme.breakpoints.up("sm"));
    const greaterThanMd = useMediaQuery(theme => theme.breakpoints.up("md"));
    const greaterThanLg = useMediaQuery(theme => theme.breakpoints.up("lg"));
    const greaterThanXl = useMediaQuery(theme => theme.breakpoints.up(1600));

    const calRef = useRef(null);
    const daysRef = useRef(null);
    const trigger = useScrollTrigger({
        disableHysteresis: true,
    });

    if (!greaterThanSm) {
        maxDaysToShow = 1;
    } else if (!greaterThanMd) {
        maxDaysToShow = 2;
    } else if (!greaterThanLg) {
        maxDaysToShow = 4;
    } else if (!greaterThanXl) {
        maxDaysToShow = 4;
    } else if (greaterThanLg) {
        maxDaysToShow = 5;
    }

    const classes = useStyles({maxDaysToShow});

    const transformedShows = {};

    function checkParams(show){
        if (paramHostName && (paramHostName !== show.host.hostName)) {
            return false;
        }

        if (paramExperience && (paramExperience !== show.experience.idName)) {
            return false;
        }

        return true;
    }

    function handleOnActiveStartDateChange({activeStartDate, value, view}){
        let momentDate = moment.utc(activeStartDate);
        let tzValue = guestViewState.timeZoneValue?.value || "America/Los_Angeles";
        let formatDate = momentDate.tz(tzValue).format("ddd MM-DD");
        let calendarValue = activeStartDate;
        guestViewState.dispatch({type: 'calendarValue', calendarValue: calendarValue, formatDate: formatDate});
    }

    useEffect(()=>{
        guestViewState.dispatch({type: 'calendarValue', calendarValue: null, formatDate: null});
    }, [Object.keys(transformedShows).length === 0])

    useEffect(()=> {
        if (!guestViewState.calendarValue && Object.keys(transformedShows).length > 0) {
            let showTime = transformedShows[Object.keys(transformedShows)[0]].shows[0].showtime;
            const {adjustedTZDate, formatDate} = getAdjustedCalendarValueAndFormatDate(showTime, tzValue);

            guestViewState.dispatch({
                type: 'calendarValue', calendarValue: adjustedTZDate, formatDate: formatDate
            });
        }
    }, [])

    let todayDate = moment.utc(Date.now());

    useEffect(() => {
        for (const show of guestViewState.shows) {
            let momentDate = moment.utc(show.showtime);
            if (momentDate < todayDate) {
                continue;
            }

            let tzValue = guestViewState.timeZoneValue?.value || "America/Los_Angeles";

            let date = momentDate.tz(tzValue).format("ddd MM-DD");
            let time = momentDate.tz(tzValue).format("h:mmA z");

            if (show.showType === "FEATURED") {
                let dayDifference = momentDate.diff(todayDate, 'days');
                let dayDescription = "";
                if (dayDifference === 0) {
                    dayDescription = "Today";
                } else if (dayDifference === 1) {
                    dayDescription = "Tomorrow"
                } else {
                    dayDescription = date;
                }

                setFeaturedShow({ ...show, ...{displayTime: time, dayDescription} });
                break;
            }
        }
    }, [guestViewState.shows])

    guestViewState.shows.forEach( (show,index) => {
        let momentDate = moment.utc(show.showtime);
        if (momentDate < todayDate) {
            return;
        }
        if (!checkParams(show)){
            return;
        };

        if ((privateShowID && show.privateShowID !== privateShowID) || (!privateShowID && show.privateShowID)) {
            return;
        }

        let tzValue = guestViewState.timeZoneValue?.value || "America/Los_Angeles";

        let date = momentDate.tz(tzValue).format("ddd MM-DD");
        let time = momentDate.tz(tzValue).format("h:mmA z");

        if (show.showType === "FEATURED") {
            return;
        }

        if (!serializeCache[show.id]) {
            serializeCache[show.id] = serializeShow(show, date, time, false, tzValue);
        }

        show.serialize = serializeCache[show.id];
        if (guestViewState.searchTerms && !checkMatch(guestViewState.searchTerms, show.serialize)) return null;

        if (!transformedShows[date]) {
            transformedShows[date] = {
                shows: [],
                disabled: true,
                showsOpen: 0
            };
        }
        const ticketsSold = show.orders.items.reduce((prev, current) => {
            prev += current.totalTicketsPurchased;
            return prev;
        }, 0);

        if (ticketsSold <= show.experience.maxPlayers) {
            transformedShows[date].disabled = false;
            transformedShows[date].showsOpen++;
        }
        transformedShows[date].shows.push({ ...show, ...{displayTime: time} });
    })

    const {formatDate} = getAdjustedCalendarValueAndFormatDate(guestViewState.calendarValue, tzValue);

    let transformedShowsDates = Object.keys(transformedShows);
    let indexOfDate = transformedShowsDates.findIndex(item => item === formatDate);
    let startIndex = indexOfDate;
    let endIndex = indexOfDate + maxDaysToShow;

    const showsToView = transformedShowsDates.slice(startIndex, endIndex);

    function handleBackToCalendar(){
        guestViewState.dispatch({type: 'showCalendar', showCalendar: true});
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    function onChange(value){
        const {adjustedTZDate, formatDate} = getAdjustedCalendarValueAndFormatDate(value, tzValue);

        guestViewState.dispatch({type: 'calendarValue', calendarValue: adjustedTZDate, formatDate: formatDate});

    }

    function tileDisabled({ date, view}){
        const {formatDate} = getAdjustedCalendarValueAndFormatDate(date, tzValue);

        if (typeof transformedShows[formatDate]?.disabled === Boolean || !transformedShows[formatDate]) {
            return true
        } else {
            return false;
        }
    }

    function tileContent({activeStartDate, date, view}){
        const {formatDate} = getAdjustedCalendarValueAndFormatDate(date, tzValue);

        let showsOpen = transformedShows[formatDate]?.showsOpen;

        if (showsOpen === 0) {
            return (
                <Typography className={classes.showsOpen} variant="caption" align="center" component="p">
                    Sold Out!
                </Typography>
            )
        }
    }

    function tileClassName({activeStartDate, date, view}){
        let className = '';
        const {adjustedTZDate, formatDate} = getAdjustedCalendarValueAndFormatDate(date, tzValue);
        const {adjustedTZDate: selectedDateAdjustedTZDate, formatDate: selectedDateFormatDate} = getAdjustedCalendarValueAndFormatDate(guestViewState.calendarValue, tzValue);

        let indexOfDate = transformedShowsDates.findIndex(item => item === formatDate);
        let indexOfSelectedDate = transformedShowsDates.findIndex(item => item === selectedDateFormatDate);

        // Check to see if this date is one of the dates being shown, but not the selected date
        if (
            indexOfDate !== -1 &&
            indexOfSelectedDate !== -1 &&
            indexOfDate > indexOfSelectedDate &&
            indexOfDate < indexOfSelectedDate + maxDaysToShow
        ) {
            className += 'secondaryDate ';
        }

        let showsOpen = transformedShows[formatDate]?.showsOpen;
        if (showsOpen === undefined) {
        } else if (showsOpen === 0) {
            className += 'noAvailableShows ';
        } else if (showsOpen > 0) {
        }

        return className;
    }

    return <>
        <Paper className={classes.callout} elevation={0}>
            <Typography color="black" variant="button" align="center">
                No public shows are available at this time.  If you are interested in booking a private show, please contact hello@adventurelab.fun
            </Typography>
        </Paper>
        {featuredShow &&
        <Container className={classes.featuredShowContainer}>
            <Card elevation={3} className={classes.featuredShow}>
                <EventBoardFeaturedShow show={featuredShow}/>
            </Card>
        </Container>
        }
        <Container className={classes.calendar} ref={calRef}>
            <Card elevation={3}>
                <Calendar
                    onActiveStartDateChange={handleOnActiveStartDateChange}
                    activeStartDate={guestViewState.calendarValue}
                    onChange={onChange}
                    value={guestViewState.calendarValue}
                    tileDisabled={tileDisabled}
                    tileContent={tileContent}
                    tileClassName={tileClassName}
                    view='month'
                    minDate={new Date()}
                    maxDate={
                        (() => {
                            const result = new Date();
                            result.setDate(result.getDate() + 90);
                            return result;
                        })()
                    }
                />
            </Card>
        </Container>

        <Grid className={classes.main} container justifyContent="center" alignItems="stretch">
            <Grid item xs={12} className={classes.showDays} ref={daysRef}>
                <Grid container spacing={2}>
                    {
                        showsToView.map(showDate =>(
                            <Grid item xs key={showDate} className={classes.show}>
                                <EventBoardDay
                                    day={showDate}
                                    shows={transformedShows[showDate].shows}
                                    soldout={transformedShows[showDate].showsOpen === 0}
                                    shouldHighlight={!selectedDateShowFirst}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
        </Grid>
        <Zoom in={trigger}>
            <Fab className={classes.fab} onClick={handleBackToCalendar}>
                <DateRange />
            </Fab>
        </Zoom>
    </>;
}

export default EventBoard;